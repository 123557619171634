<template>
  <div class="dashboard__container">
    <div class="dashboard__container--body" v-if="user">
      <div class="dashboard__container--body--col">
        <div class="whiteBack mb-5">
          <h4 class="mb-1">Employee Status</h4>
          <label for="status">Choose Status:</label>
          <v-select
            class="mt-2 mb-2"
            label="title" 
            :options="statuses"
            v-model="user.employmentStatus"
            @input="updateUser()"
            >
          </v-select>
        </div>

        

        <div class="whiteBack mb-5">
          <h4 class="mb-1">Employment Dates:</h4>
          <div class="mb-3">
            <label for="start">Start Date:</label>
            <div class="flex">
              <input type="date" v-model.trim="user.jumpHireDate" id="start" @input="updateUser()" />
              <button class="btn btn__small btn__outlined ml-3" @click="clearHire()">Clear</button>
            </div>
            
          </div>

          <div>
            <label for="start">End Date:</label>
            <div class="flex">
              <input type="date" v-model.trim="user.jumpTerminationDate" id="start" @input="updateUser()" />
              <button class="btn btn__small btn__outlined ml-3" @click="clearTermination()">Clear</button>
            </div>
          </div>
        </div>

        <div class="whiteBack mb-5" v-if="userPipeline && userPipeline.length >= 1">
          <h4 class="mb-1">Active Pipeline</h4>
          <div v-for="(item, index) in userPipeline" class="mb-2">
            <router-link :to="`/orders/` + item.id">{{item.title}}</router-link>
          </div>
        </div>

        <div class="whiteBack mb-5" v-if="jobAssignments && jobAssignments.length >= 1">
          <div class="flex justify-space-between mb-1">
            <h4>Active Assignment</h4>
            <router-link :to="`/orders/` + user.employmentAssignment.eventId + `/staffing/placements`" v-if="user.employmentAssignment">
              <button class="btn btn__primary btn__small">Open</button>
            </router-link>
          </div>
          <label for="status">Choose Job:</label>
          <v-select
            class="mt-2 mb-2"
            label="eventName" 
            :options="jobAssignments"
            v-model="user.employmentAssignment"
            @input="updateUserAssignment()"
            >
          </v-select>
          <div v-if="user.employmentAssignment && user.employmentAssignment.shift" class="mt-3">
            <span class="flex justify-space-between">

              <span v-if="user.employmentAssignment">
                <div class="strong" v-if="user.employmentAssignment.shift.event">{{user.employmentAssignment.shift.event}}</div>
                <div  v-if="user.employmentAssignment.shift && user.employmentAssignment.shift.position" class="caption"><span v-if="user.employmentAssignment.shift.name">{{user.employmentAssignment.shift.name}} - </span>{{user.employmentAssignment.shift.position.title}}</div>
              </span>
            </span>
            <div v-if="user.employmentAssignment.shift && user.employmentAssignment.shift.position" class="mb-2 caption">${{user.employmentAssignment.shift.position.rate}}/hour</div>
            <div class="caption mb-2" v-if="user.employmentAssignment.shift && user.employmentAssignment.shift.address">
              <div>{{user.employmentAssignment.shift.address.street_number}} {{user.employmentAssignment.shift.address.street}}</div>
              <div>{{user.employmentAssignment.shift.address.city}}, {{user.employmentAssignment.shift.address.state}} {{user.employmentAssignment.shift.address.zip}}</div>
            </div>
            <div class="strong">Shift Schedule</div>
      <div class="caption mb-1" v-if="user.employmentAssignment.shift && user.employmentAssignment.shift.sundayStart && user.employmentAssignment.shift.sundayEnd">
        Sunday: {{formatAMPM(user.employmentAssignment.shift.sundayStart)}} - {{formatAMPM(user.employmentAssignment.shift.sundayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="user.employmentAssignment.shift && user.employmentAssignment.shift.mondayStart && user.employmentAssignment.shift.mondayEnd">
        Monday: {{formatAMPM(user.employmentAssignment.shift.mondayStart)}} - {{formatAMPM(user.employmentAssignment.shift.mondayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="user.employmentAssignment.shift && user.employmentAssignment.shift.tuesdayStart && user.employmentAssignment.shift.tuesdayEnd">
        Tuesday: {{formatAMPM(user.employmentAssignment.shift.tuesdayStart)}} - {{formatAMPM(user.employmentAssignment.shift.tuesdayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="user.employmentAssignment.shift && user.employmentAssignment.shift.wednesdayStart && user.employmentAssignment.shift.wednesdayEnd">
        Wednesday: {{formatAMPM(user.employmentAssignment.shift.wednesdayStart)}} - {{formatAMPM(user.employmentAssignment.shift.wednesdayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="user.employmentAssignment.shift && user.employmentAssignment.shift.thursdayStart && user.employmentAssignment.shift.thursdayEnd">
        Thursday: {{formatAMPM(user.employmentAssignment.shift.thursdayStart)}} - {{formatAMPM(user.employmentAssignment.shift.thursdayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="user.employmentAssignment.shift && user.employmentAssignment.shift.fridayStart && user.employmentAssignment.shift.fridayEnd">
        Friday: {{formatAMPM(user.employmentAssignment.shift.fridayStart)}} - {{formatAMPM(user.employmentAssignment.shift.fridayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="user.employmentAssignment.shift && user.employmentAssignment.shift.saturdayStart && user.employmentAssignment.shift.saturdayEnd">
        Saturday: {{formatAMPM(user.employmentAssignment.shift.saturdayStart)}} - {{formatAMPM(user.employmentAssignment.shift.saturdayEnd) | moment("h:mm:ss a") }}
      </div>
          </div>
        </div>

        

        <!-- <div class="whiteBack mb-5" v-if="jobAssignments && jobAssignments.length >= 1">
          <h4 class="mb-1">Active Assignment</h4>
          <label for="status">Choose Job:</label>
          <v-select
            class="mt-2 mb-2"
            label="eventName" 
            :options="jobAssignments"
            v-model="user.employmentAssignment"
            @input="updateUser()"
            >
          </v-select>
        </div> -->
        
        <ProfileEmployeeOnboard :user="user" :userEmployeePayProfile="userEmployeePayProfile" class="mb-5" />
      </div>

        <div class="dashboard__container--body--col">

          <div class="whiteBack mb-5">
            <h4>Send Onboarding Text</h4>
            <div class="mb-1 mt-0">
             <span v-if="user.onboardingTextSent" class="caption">
              Last Sent: {{user.onboardingTextSent.toDate() | moment("MMMM Do YYYY, h:mm a") }}
              </span>
            </div>
            <button class="btn btn__outlined btn__small mt-2 mb-1" @click="sendOnboardingText()">
              Send Text
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest2">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
            <div class="caption mt-2">"Hi ${name}. Jump just sent you a Docusign email with your offer letter and onboarding packet. We also sent you an email from Everee, our payroll company. You will need to onboard as a W2 Employee of Jump Employee Services LLC."</div>
          </div>

          <div class="whiteBack mb-5">
            <h4 class="mb-1">I-9 Verification</h4>
            <div class="mb-3 pt-1">
              <label for="i9">I-9 Complete:</label>
              <input type="checkbox" v-model="user.i9Complete" id="i9" class="ml-3" @change="updateUser()"/>
            </div>
            <div class="mb-3">
              <label for="i9">I-9 Failed:</label>
              <input type="checkbox" v-model="user.i9Failed" id="i9" class="ml-3" @change="updateUser()"/>
            </div>
            <button class="btn btn__outlined btn__small mt-2 mb-1" @click="sendI9Text()">
              Send Reminder Text
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest3">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
            <div class="caption mt-2">"Hi ${name}. Please log in to your Everee payroll account for Jump Employee Services LLC and upload the required I-9 documents. This must be complete for you to work. For a list of acceptable documents, click this link: https://www.uscis.gov/i-9-central/form-i-9-acceptable-documents"
            </div>
            <div class="mb-1 mt-2">
             <span v-if="user.i9TextSent" class="caption">
              Last Sent: {{user.i9TextSent.toDate() | moment("MMMM Do YYYY, h:mm a") }}
              </span>
            </div>
            
          </div>



          <div class="whiteBack mb-5">
            <h4 class="mb-1">E-Verification</h4>
            <label for="status">Choose Status:</label>
            <v-select
              class="mt-2 mb-2"
              label="title" 
              :options="eStatuses"
              v-model="user.eVerificationStatus"
              @input="updateUser()"
              >
            </v-select>
            <div class="mb-3 mt-3" v-if="user && user.eVerificationStatus">
              <label for="statusUpdate">Status Updated Date:</label>
              <div class="flex">
                <input type="date" v-model.trim="user.eVerificationStatusUpdated" id="statusUpdate" @input="updateUser()" />
                <button class="btn btn__small btn__outlined ml-3" @click="clearStatusUpdated()">Clear</button>
              </div>
            </div>
            <div class="mb-3 caption mt-5">
              <div v-if="user && user.eVerificationStatus && user.eVerificationStatus == 'Employment Authorized'">
                <div>*The employee’s information matched records available to SSA and/or DHS.</div>
                <div>Most E-Verify cases receive a case result of Employment Authorized. Employment Authorized means that the information entered into E-Verify matched records available to SSA and/or DHS confirming employment eligibility of the employee whose information was entered. E-Verify automatically closes cases resulting in Employment Authorized. You can locate the closed case and view case details in View/Search Cases. See Section 4.2 Case Alerts and View/Search cases for guidance on how to search for and view existing cases in E-Verify. At this stage, you should record the case number on this employee’s Form I-9 or print out the Case Details page and attach it to the Form I-9.</div>
              </div>
              <div v-if="user && user.eVerificationStatus && user.eVerificationStatus == 'Needs More Time'">
                <div>*This case was referred to DHS for further verification.</div>
                <div>A case result of E-Verify Needs More Time means that DHS cannot verify the data and needs more time. The case is automatically referred for further verification. DHS will respond to most of these cases within 24 hours, although some responses may take up to 3 federal government working days. No action is required by either you or the employee at this time, but you can check E-Verify daily for a response. Employers may not terminate or take any other adverse action against an employee (such as denying work, delaying training, withholding pay, or otherwise assuming that he or she is not authorized to work) because of the mismatch, until the mismatch becomes a Final Nonconfirmation. See E-Verify Needs More Time – Process Overview. </div>
              </div>
              <div v-if="user && user.eVerificationStatus && user.eVerificationStatus == 'Tentative Nonconfirmation'">
                <div>*Information did not match records available to SSA and/or DHS. Additional action is required.</div>
                <div>A mismatch means that the information entered into E-Verify does not match records available to SSA and/or DHS. It is possible for an employee to receive a dual mismatch, which means the case received a mismatch result from both agencies at the same time because information entered into E-Verify does not match records available to both SSA and DHS. E Verify identifies the agency or agencies associated with the mismatch in the mismatch Further Action Notice. When E-Verify displays a mismatch case result, the employer must first notify the employee and complete the referral process as soon as possible within 10 federal government working days after E-Verify issued the mismatch result.</div>
              </div>
              <div v-if="user && user.eVerificationStatus && user.eVerificationStatus == 'Case in Continuance'">
                <div>*The employee has visited an SSA field office or contacted DHS, but more time is needed to determine a final case result.</div>
                <div>A Case in Continuance status indicates that the employee has contacted DHS and/or visited an SSA field office, but more time is needed to determine a final case result. The reason DHS or SSA needs more time varies with each situation. Employers should check E-Verify periodically for case result updates. Employers may not terminate or take any other adverse action against an employee (such as denying work, delaying training, withholding pay, or otherwise assuming that he or she is not authorized to work) because of the mismatch, until the mismatch becomes a Final Nonconfirmation.</div>
              </div>
              <div v-if="user && user.eVerificationStatus && user.eVerificationStatus == 'Close Case and Resubmit'">
                <div>*SSA or DHS requires that you close the case and create a new case for this employee. This result may be issued when the employee’s U.S. passport, passport card, or driver’s license information is incorrect.</div>
                <div>Your employee’s case may receive a Close Case and Resubmit case result if SSA and/or DHS are unable to process the case and confirm employment eligibility. The employer should close the case in E-Verify and create a new case. When the user clicks Close and Create a New Case, the case is automatically closed and E-Verify opens a new case for the user to begin entering the employee’s information from Form I-9.</div>
              </div>
              <div v-if="user && user.eVerificationStatus && user.eVerificationStatus == 'Final Nonconfirmation'">
                <div>*E-Verify cannot confirm the employee’s employment eligibility after the employee visited SSA or contacted DHS.</div>
                <div>
                  A case receives a Final Nonconfirmation case result when E-Verify cannot confirm an employee’s employment eligibility after:
                  <ul>
                    <li>The employee has visited a SSA field office and/or contacted DHS during the mismatch referral process;</li>
                    <li>The employee failed to visit SSA and/or call DHS within 8 federal government working days; Or</li>
                    <li>The employee did not give the employer their decision whether to take action by the end of the 10th federal government working day after E-Verify issued the mismatch result.</li>
                  </ul>Employers must close the case once a case receives a Final Nonconfirmation result. The employer may terminate employment based on a case result of Final Nonconfirmation with no civil or criminal liability as noted in “Responsibilities of the Employer,” Article II, Section A, paragraph 13 of the MOU.
                </div>
              </div>
            </div>
            <!-- <label for="status">Choose Status:</label>
            <v-select
              class="mt-2 mb-2"
              label="title" 
              :options="statuses"
              v-model="user.employmentStatus"
              @input="updateUser()"
              >
            </v-select> -->
          </div>

          <div class="whiteBack mb-5">
            <h4>ABM / Beeline Status</h4>
            <div class="mb-3">
              <label for="beelline">Added to Beeline:</label>
              <input type="checkbox" v-model="user.beeline" id="beelline" class="ml-3" @change="updateUser()" />
            </div>
          </div>


          <div class="whiteBack mb-5">
            <h4 class="mb-2">Upload Documents</h4>
            <div class="flex flex-wrap">
             <!--  <div>
                <a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/Paid%20Time%20Off%20(PTO)%20Policy.pdf?alt=media&token=b31074ed-1bf6-436e-a8cd-d3e5281409df" target="_blank">
                  <button class="btn btn__primary btn__small">Print PTO Policy</button>
                </a>
              </div> -->
              <span class="mr-3 mb-2">
                <input type="file" ref="fileInputOffer" id="offerLetter" accept="image/*,application/pdf,.doc" @change="previewOffer" hidden>
                <div>
                  <button @click="chooseOffer()" class="btn btn__primary btn__xsmall">Offer Letter</button>
                </div>
                <div>
                  <progress :value="uploadValue" max="100" v-if="showBar2"></progress>
                </div>
              </span>
              <span class="mr-3 mb-2">
                <input type="file" ref="fileInputPTO" id="ptopolicy" accept="image/*,application/pdf,.doc" @change="previewPTO" hidden>
                <div>
                  <button @click="choosePTO()" class="btn btn__primary btn__xsmall">PTO Policy</button>
                </div>
                <div>
                  <progress :value="uploadValue" max="100" v-if="showBar"></progress>
                </div>
              </span>
              
              <span class="mr-3 mb-2">
                <input type="file" ref="fileInputHS" id="HSpolicy" accept="image/*,application/pdf,.doc" @change="previewHS" hidden>
                <div>
                  <button @click="chooseHS()" class="btn btn__primary btn__xsmall">H&S Manual</button>
                </div>
                <div>
                  <progress :value="uploadValue" max="100" v-if="showBar3"></progress>
                </div>
              </span>
              <span class="mr-3 mb-2">
                <input type="file" ref="fileInputHeat" id="heatIllness" accept="image/*,application/pdf,.doc" @change="previewHeat" hidden>
                <div>
                  <button @click="chooseHeat()" class="btn btn__primary btn__xsmall">Heat Illness</button>
                </div>
                <div>
                  <progress :value="uploadValue" max="100" v-if="showBar4"></progress>
                </div>
              </span>
              <span class="mr-3 mb-2">
                <input type="file" ref="fileInputCOVID" id="COVIDTraining" accept="image/*,application/pdf,.doc" @change="previewCOVID" hidden>
                <div>
                  <button @click="chooseCOVID()" class="btn btn__primary btn__xsmall">COVID Training</button>
                </div>
                <div>
                  <progress :value="uploadValue" max="100" v-if="showBar5"></progress>
                </div>
              </span>
              <span class="mr-3 mb-2">
                <input type="file" ref="fileInputOther" id="OtherTraining" accept="image/*,application/pdf,.doc" @change="previewOther" hidden>
                <div>
                  <button @click="chooseOther()" class="btn btn__primary btn__xsmall">Other</button>
                </div>
                <div>
                  <progress :value="uploadValue" max="100" v-if="showBar6"></progress>
                </div>
              </span>
            </div>
          </div>
          
            
          <div class="whiteBack mb-5" v-if="(user.employeeFiles && user.employeeFiles.length >= 1)">
            <div class="mb-3">
              <h5 class="mb-2">Employee Files from Everee</h5>
              <vue-good-table
                :columns="columns"
                :rows="user.employeeFiles"
                styleClass="vgt-table condensed"
                compactMode
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 10,
                }"
              >
               <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'downloadUrl'">
                    <a v-if="props.row.downloadUrl" :href="props.row.downloadUrl" target="_blank"><i class="fas fa-external-link"></i></a>
                  </span>
                  
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
                
              </vue-good-table>
            </div>
          </div>
          <div class="whiteBack mb-5" v-if="(user && user.letters && user.letters.length >= 1)">
           

            <div class="mb-3">
              <h5 class="mb-2">Offer Letters</h5>
              <vue-good-table
                :columns="columns2"
                :rows="user.letters"
                styleClass="vgt-table condensed"
                compactMode
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 10,
                }"
              >
               <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'url'">
                    <a v-if="props.row.url" :href="props.row.url" target="_blank"><i class="fas fa-external-link"></i></a>
                  </span>
                  <span v-else-if="props.column.field == 'date'">
                    <span v-if="props.row.date">
                     {{formatDate(props.row.date)}}
                    </span>
                  </span>
                  
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
                
              </vue-good-table>
            </div>
          </div>
          
          <div class="whiteBack mb-5" v-if="(user && user.ptos && user.ptos.length >= 1)">

            <div class="mb-3">
              <h5 class="mb-2">PTO Policy</h5>
              <vue-good-table
                :columns="columns2"
                :rows="user.ptos"
                styleClass="vgt-table condensed"
                compactMode
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 10,
                }"
              >
               <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'url'">
                    <a v-if="props.row.url" :href="props.row.url" target="_blank"><i class="fas fa-external-link"></i></a>
                  </span>
                  <span v-else-if="props.column.field == 'date'">
                    <span v-if="props.row.date">
                     {{formatDate(props.row.date)}}
                    </span>
                  </span>
                  
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
                
              </vue-good-table>
            </div>
          </div>

          <div class="whiteBack mb-5" v-if="(user && user.hsmanuals && user.hsmanuals.length >= 1)">

            <div class="mb-3">
              <h5 class="mb-2">Health & Safety Manuals</h5>
              <vue-good-table
                :columns="columns2"
                :rows="user.hsmanuals"
                styleClass="vgt-table condensed"
                compactMode
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 10,
                }"
              >
               <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'url'">
                    <a v-if="props.row.url" :href="props.row.url" target="_blank"><i class="fas fa-external-link"></i></a>
                  </span>
                  <span v-else-if="props.column.field == 'date'">
                    <span v-if="props.row.date">
                     {{formatDate(props.row.date)}}
                    </span>
                  </span>
                  
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
                
              </vue-good-table>
            </div>
          </div> 
          <div class="whiteBack mb-5" v-if="(user && user.heatIllness && user.heatIllness.length >= 1)">

            <div class="mb-3">
              <h5 class="mb-2">Heat Illness</h5>
              <vue-good-table
                :columns="columns2"
                :rows="user.heatIllness"
                styleClass="vgt-table condensed"
                compactMode
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 10,
                }"
              >
               <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'url'">
                    <a v-if="props.row.url" :href="props.row.url" target="_blank"><i class="fas fa-external-link"></i></a>
                  </span>
                  <span v-else-if="props.column.field == 'date'">
                    <span v-if="props.row.date">
                     {{formatDate(props.row.date)}}
                    </span>
                  </span>
                  
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
                
              </vue-good-table>
            </div>
          </div> 

          <div class="whiteBack mb-5" v-if="(user && user.covidTraining && user.covidTraining.length >= 1)">

            <div class="mb-3">
              <h5 class="mb-2">COVID Training</h5>
              <vue-good-table
                :columns="columns2"
                :rows="user.covidTraining"
                styleClass="vgt-table condensed"
                compactMode
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 10,
                }"
              >
               <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'url'">
                    <a v-if="props.row.url" :href="props.row.url" target="_blank"><i class="fas fa-external-link"></i></a>
                  </span>
                  <span v-else-if="props.column.field == 'date'">
                    <span v-if="props.row.date">
                     {{formatDate(props.row.date)}}
                    </span>
                  </span>
                  
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
                
              </vue-good-table>
            </div>
          </div>

          <div class="whiteBack mb-5" v-if="(user && user.otherUploads && user.otherUploads.length >= 1)">

            <div class="mb-3">
              <h5 class="mb-2">Other Uploads</h5>
              <vue-good-table
                :columns="columns2"
                :rows="user.otherUploads"
                styleClass="vgt-table condensed"
                compactMode
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 10,
                }"
              >
               <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'url'">
                    <a v-if="props.row.url" :href="props.row.url" target="_blank"><i class="fas fa-external-link"></i></a>
                  </span>
                  <span v-else-if="props.column.field == 'date'">
                    <span v-if="props.row.date">
                     {{formatDate(props.row.date)}}
                    </span>
                  </span>
                  
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
                
              </vue-good-table>
            </div>
          </div>
        </div> 
  
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import * as moment from 'moment'
const fb = require('../../firebaseConfig.js')
import ProfileEmployeeOnboard from '@/components/Profile/ProfileEmployeeOnboard.vue'

export default {
  props: ['user', 'dnr'],
  name: 'userEmployeeOnboarding',
  data: () => ({
    imageData: null,
    uploadValue: 0,
    performingRequest2: false,
    performingRequest3: false,
    showBar:false,
    showBar2:false,
    showBar3:false,
    showBar4:false,
    showBar5:false,
    showBar6:false,
    eStatuses: ['Employment Authorized', 'Needs More Time', 'Tentative Nonconfirmation', 'Case in Continuance', 'Close Case and Resubmit', 'Final Nonconfirmation'],
    statuses: ['Onboarding', 'Active', 'Terminated', 'On-Hold', 'Resigned', 'On-Leave', 'HiredDirect', 'NeverStarted'],
    columns: [
      {
        label: 'Type',
        field: 'documentType',
        sortable: false,
        width:'120px'
      },
      {
        label: 'Name',
        field: 'fileName',
        sortable: false
      },
      {
        label: 'Link',
        field: 'downloadUrl',
        sortable: false,
        width:'60px'
      }
    ],
    columns2: [
      {
        label: 'Date Uploaded',
        field: 'date',
        sortable: false,
        width:'120px'
      },
      {
        label: 'Link',
        field: 'url',
        sortable: false,
        width:'60px'
      }
    ]
  }),
  created() {
    this.$store.dispatch('getUserPipeline', this.$route.params.id)
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'userEmployeePayProfile', 'userAssignments', 'userPipeline']),
    jobAssignments() {
      return this.userAssignments
    }
  },
  methods: {
    clearTermination() {
      this.user.jumpTerminationDate = ''
      let user = this.user
      this.$store.dispatch('updateTheUser', user)
    },
    clearHire() {
      this.user.jumpHireDate = ''
      let user = this.user
      this.$store.dispatch('updateTheUser', user)
    },
    clearStatusUpdate() {
      this.user.eVerificationStatusUpdated = ''
      let user = this.user
      this.$store.dispatch('updateTheUser', user)
    },
    async sendOnboardingText () {
      this.performingRequest2 = true

      const date = new Date()
      let touch = {
        contactedById: this.userProfile.id,
        contactedByFirstName: this.userProfile.firstName,
        contactedByLastName: this.userProfile.lastName,
        method: "Texted Onboarding Message",
        notes: '',
        touched: date,
      }
      await this.$store.dispatch("addTouchLog", {
        userId: this.user.id,
        log: touch
      })

      let newText = {
        name: this.user.firstName,
        phone: this.user.phone,
        type: 'Onboarding Welcome'
      }

      console.log(newText)

      await fb.usersCollection.doc(this.user.id).update({
        onboardingTextSent: fb.firestore.FieldValue.serverTimestamp()
      })

      await this.$store.dispatch("sendOnboardingMessage", newText)
      setTimeout(() => {
          this.performingRequest2 = false
      }, 1000)
    },
    async sendI9Text () {
      this.performingRequest3 = true

      const date = new Date()
      let touch = {
        contactedById: this.userProfile.id,
        contactedByFirstName: this.userProfile.firstName,
        contactedByLastName: this.userProfile.lastName,
        method: "Texted I-9 Reminder",
        notes: '',
        touched: date,
      }
      await this.$store.dispatch("addTouchLog", {
        userId: this.user.id,
        log: touch
      })

      let newText = {
        name: this.user.firstName,
        phone: this.user.phone,
        type: 'I-9 Reminder'
      }

      console.log(newText)

      await fb.usersCollection.doc(this.user.id).update({
        i9TextSent: fb.firestore.FieldValue.serverTimestamp()
      })

      await this.$store.dispatch("sendI9Reminder", newText)
      setTimeout(() => {
          this.performingRequest3 = false
      }, 1000)
    },
    updateUser(){
      let user = this.user
      console.log(user)
      this.$store.dispatch('updateTheUser', user)
    },
    updateUserAssignment(){
      let user = this.user
      console.log(user)
      let ass = {
        name: user.employmentAssignment.name,
        id: user.employmentAssignment.id,
        // eventInfo: user.employmentAssignment.eventInfo,
        eventName: user.employmentAssignment.eventName,
        position: user.employmentAssignment.position,
        shift: user.employmentAssignment.shift,
      }
      this.$store.dispatch('updateTheUserAssignment', {
        userId: user.id,
        employmentAssignment: ass

      })
    },
    updateUserToOnboard() {
      let user = this.user
      this.$store.dispatch('updateUserToOnboard', user)
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;

        return moment.utc(postedDate).local().format('MM/DD/YYYY - HH:mm A')
        // return moment(postedDate).format('HH:MM A')
      } else {
        return null
      }
    },
    
    chooseOffer() {
      document.getElementById("offerLetter").click()
    },
    previewOffer(event) {
      console.log(event)
      this.uploadValue=0;
      this.imageData=event.target.files[0]
      this.onUploadFile()
    },

    onUploadFile() {
      console.log('onUpload')
      this.showBar2 = true
      let user = this.user
      let date = new Date();
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          var docRef = fb.usersCollection.doc(user.id)
          docRef.update({
            letters: fb.firestore.FieldValue.arrayUnion({
              date: date,
              url: downloadURL
            })
          });
          return user.letters.push({
            date: date,
            url: downloadURL
          })
        })
        this.showBar2 = false
      })
      this.imageData = null
      this.$refs.fileInputOffer.value=null
    },
    choosePTO() {
      document.getElementById("ptopolicy").click()
    },
    previewPTO(event) {
      console.log(event)
      this.uploadValue=0;
      this.imageData=event.target.files[0]
      this.onUploadPTO()
    },
    onUploadPTO() {
      console.log('onUpload')
      this.showBar = true
      let user = this.user
      let date = new Date();
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          var docRef = fb.usersCollection.doc(user.id)
          docRef.update({
            ptos: fb.firestore.FieldValue.arrayUnion({
              date: date,
              url: downloadURL
            })
          });
          return user.ptos.push({
            date: date,
            url: downloadURL
          })
        })
        this.showBar = false
      })
      this.imageData = null
      this.$refs.fileInputPTO.value=null
    },
    chooseHS() {
      document.getElementById("HSpolicy").click()
    },
    previewHS(event) {
      console.log(event)
      this.uploadValue=0;
      this.imageData=event.target.files[0]
      this.onUploadHS()
    },
    onUploadHS() {
      console.log('onUpload Health')
      this.showBar3 = true
      let user = this.user
      let date = new Date();
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          var docRef = fb.usersCollection.doc(user.id)
          docRef.update({
            hsmanuals: fb.firestore.FieldValue.arrayUnion({
              date: date,
              url: downloadURL
            })
          });
          return user.hsmanuals.push({
            date: date,
            url: downloadURL
          })
        })
        this.showBar3 = false
      })
      this.imageData = null
      this.$refs.fileInputHS.value=null
    },
    chooseHeat() {
      document.getElementById("heatIllness").click()
    },
    previewHeat(event) {
      console.log('Preview heat')
      this.uploadValue=0;
      this.imageData=event.target.files[0]
      this.onUploadHeat()
    },
    onUploadHeat() {
      console.log('onUpload Heat')
      this.showBar4 = true
      let user = this.user
      let date = new Date();
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          var docRef = fb.usersCollection.doc(user.id)
          docRef.update({
            heatIllness: fb.firestore.FieldValue.arrayUnion({
              date: date,
              url: downloadURL
            })
          });
          return user.heatIllness.push({
            date: date,
            url: downloadURL
          })
        })
        this.showBar4 = false
      })
      this.imageData = null
      this.$refs.fileInputHeat.value=null
    },
    chooseCOVID() {
      document.getElementById("COVIDTraining").click()
    },
    previewCOVID(event) {
      console.log('Preview COVID')
      this.uploadValue=0;
      this.imageData=event.target.files[0]
      this.onUploadCOVID()
    },
    onUploadCOVID() {
      console.log('onUpload COVID')
      this.showBar5 = true
      let user = this.user
      let date = new Date();
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          var docRef = fb.usersCollection.doc(user.id)
          docRef.update({
            covidTraining: fb.firestore.FieldValue.arrayUnion({
              date: date,
              url: downloadURL
            })
          });
          return user.covidTraining.push({
            date: date,
            url: downloadURL
          })
        })
        this.showBar5 = false
      })
      this.imageData = null
      this.$refs.fileInputHeat.value=null
    },
    chooseOther() {
      document.getElementById("OtherTraining").click()
    },
    previewOther(event) {
      console.log(event)
      this.uploadValue=0;
      this.imageData=event.target.files[0]
      this.onUploadOther()
    },
    formatAMPM(date) {

      if (typeof date === "string") {
        // console.log(date)
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        if (date === "00:00") {
          return `12:00 AM`;
        } else {
          return `${hours}:${minutes} ${ampm}`;
        }

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;
        return strTime;

      }
      return date;
      
    },
    onUploadOther() {
      console.log('onUpload')
      this.showBar6 = true
      let user = this.user
      let date = new Date();
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          var docRef = fb.usersCollection.doc(user.id)
          docRef.update({
            otherUploads: fb.firestore.FieldValue.arrayUnion({
              date: date,
              url: downloadURL
            })
          });
          return user.otherUploads.push({
            date: date,
            url: downloadURL
          })
        })
        this.showBar6 = false
      })
      this.imageData = null
      this.$refs.fileInputOther.value=null
    },
    deleteUploadedFile(u, index) {
      console.log(u)
      let user = this.user
      user.letters.splice(index, 1)
      this.$store.dispatch('updateUser', user)
    },
    onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
  },
  components: {
    Loader,
    ProfileEmployeeOnboard,
  },
  beforeDestroy() {
    this.$store.dispatch('clearUserPipeline')
  }
}

</script>